<template>
    <div class="home">
        <div id="container"></div> 
        <!-- 设备底部弹出框 -->
        <div class="info-control" v-if="devShow">
			<div class="top">
				<div class="title">
					<i class="iconfont">&#xe60a;</i>
					<span class="font-title">设备参数</span>
				</div>
				<div class="handle-area">
					<i class="iconfont alarm-red" @click="alarmDialog = true">&#xe600;</i>
					<i v-if="devType === 'bucket'" class="iconfont set-blue" @click="goParam">&#xe795;</i>
					<i class="iconfont" @click="devShow = false">&#xe724;</i>
				</div>
			</div>
			<div v-if="devType === 'bucket'">
				<div class="inner">
					<div class="block">
						<span class="label-0 font">设备名称</span>
						<span class="value-0 font">{{dev.devName || '加载中'}}</span>
					</div>
					<div class="block">
						<span class="label-0 font">当前状态</span>
						<span class="value-0 font">{{dev.devStatus !== 'offline' ? '在线' : '离线'}}</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">地理经度</span>
						<span class="value-0 font">{{dev.lon || '0.000'}} °</span>
					</div>
					<div class="block">
						<span class="label-0 font">地理纬度</span>
						<span class="value-0 font">{{dev.lat || '0.000'}} °</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">GPS模块</span>
						<span class="value-0 font">{{dev.gpsMode ==='0' ? '普通' : '差分'}}</span>
					</div>
					<div class="block">
						<span class="label-0 font">GPS卫星数</span>
						<span class="value-0 font">{{dev.gpsNum || 0}}</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">OLED节目单</span>
						<span class="value-0 font">{{dev.oledProgram === '1' ? '自检' : '实时状态'}}</span>
					</div>
					<div class="block">
						<span class="label-0 font">LED节目单</span>
						<span class="value-0 font">{{dev.led || '加载中'}}</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">喇叭节目单</span>
						<span class="value-0 font">{{dev.speakerProgram === '1' ? '开机' : dev.speakerProgram === '2' ? '可以部署' :dev.speakerProgram === '3' ? '距离*米' :'报警'}}</span>
					</div>
					<div class="block">
						<span class="label-0 font">喇叭音量</span>
						<span class="value-0 font">{{dev.speakerVol || 0}}</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">芯片温度</span>
						<span class="value-0 font">{{dev.chipTemp || '0.00'}} ℃</span>
					</div>
					<div class="block">
						<span class="label-0 font">电池电压</span>
						<span class="value-0 font">{{dev.batVol || 0}} mv</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">充电电压</span>
						<span class="value-0 font">{{dev.chargeVol || 0}} mv</span>
					</div>
					<div class="block">
						<span class="label-0 font">电池电量</span>
						<span class="value-0 font">{{dev.batLevel || 0}} %</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">信号强度</span>
						<span class="value-0 font">{{dev.wirelessLevel || 0}}</span>
					</div>
					<div class="block">
						<span class="label-0 font">更新时间</span>
						<span class="value-0 font">{{dev.recordTime || '0000-00-00 00:00:00'}}</span>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">设备编号</span>
						<span class="value-0 font">{{lamp.devCode}}</span>
					</div>
					<div class="block">
						<span class="label-0 font">设备状态</span>
						<span class="value-0 font">{{lamp.devStatus !== 'offline' ? '在线' : '离线'}}</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">地理经度</span>
						<span class="value-0 font">{{Number(lamp.lon).toFixed(4) || '0.0000'}} °</span>
					</div>
					<div class="block">
						<span class="label-0 font">地理纬度</span>
						<span class="value-0 font">{{Number(lamp.lat).toFixed(4) || '0.0000'}} °</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">设备电量</span>
						<span class="value-0 font">{{lamp.buckets[0] ? lamp.buckets[0].batLevel : 0}} %</span>
					</div>
					<div class="block">
						<span class="label-0 font">电池电压</span>
						<span class="value-0 font">{{lamp.buckets[0] ? lamp.buckets[0].batVol : 0}} mV</span>
					</div>
				</div>
				<div class="inner">
					<div class="block">
						<span class="label-0 font">信号强度</span>
						<span class="value-0 font">{{lamp.buckets[0] ? lamp.buckets[0].uwbLevel : 0}}</span>
					</div>
					<div class="block">
						<span class="label-0 font">卫星数量</span>
						<span class="value-0 font">{{lamp.buckets[0] ? lamp.buckets[0].gpsNum : 0}}</span>
					</div>
				</div>
                <view class="inner">
					<view class="block">
						<text class="label-0 font">设备距离</text>
						<view class="value-0" v-if="lamp.distanceText.length>0">
							<view v-for="(item,index) in lamp.distanceText" :key="index"><text class="font-line">{{item}}</text></view>
						</view>
						<view class="value-0" v-else>
							<text class="font-line">暂无数据</text>
						</view>
					</view>
				</view>
			</div>
		</div>
        <!-- 告警信息列表 -->
        <div class="alarm" v-if="alarmList.length">
            <span class="title">告警信息</span>
		    <div class="info">
				<div class="block-flex" v-for="(item,index) in alarmList" :key="index">
					<div class="wid-7">
						<span class="text">{{item.content}}</span>
					</div>
					<div class="wid-3">
						<button class="relieve" @click="relieve(item.devCode,index)"><span class="text">解除</span></button>
					</div>
				</div>
			</div>
		</div>
		<!-- 确认告警弹窗 -->
        <van-dialog v-model="alarmDialog" title="确定启动告警警报？" show-cancel-button @confirm="confirmAlarm">
            <div class="dialog-body">
                <van-radio-group v-model="alarmTypeVal" direction="horizontal">
                    <van-radio name="1">单一设备</van-radio>
                    <van-radio name="2">本组设备</van-radio>
                </van-radio-group>
            </div>
        </van-dialog>

        <tabBar />
    </div>
</template>

<script>
import tabBar from '@/components/bucket/tabBar.vue';
const mqtt = require('mqtt');
import MQTT_OPTIONS from '@/config/mqtt.js'

export default {
    components:{
        tabBar
    },
    data(){
        return{
            projectCode:null,
            devShow:false,
            dev:{},
            lamp:{
                distanceText:[],
                buckets:[
                    {
                        uwbLevel:0,
                        batLevel:0,
                        batVol:0,
                        gpsNum:0
                    }
                ]
            },
            devType:'bucket',//1是锥桶设备 2是行人佩戴设备
            alarmList:[],
            alarmDialog:false,
            alarmTypeVal:'1',
            client:null,
            devList:[],
            markArr:[],
            optLed:[],
            firstView:false,
            alarmList:[],
            polygon:null
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.loadProgram();
        this.createMap();
        this.mqttMsg();
    },
    beforeDestroy(){
        this.client ? this.client.end() : void 0;
        console.log('主动关闭mqtt')
    },
    methods:{
        createMap(){
            let tabSize = document.getElementsByClassName('van-tabbar')[0].scrollHeight; // 获取tab栏的高度
            document.getElementById('container').style.height = (window.screen.height - tabSize )+'px';
            this.map = new AMap.Map('container',{
                zooms:[4,19], //设置缩放级别范围 4-19 级
            });
            AMap.plugin(['AMap.ToolBar','AMap.Scale'],() => {//异步同时加载多个插件
                var toolbar = new AMap.ToolBar();
                var scale = new AMap.Scale();
                this.map.addControl(toolbar);
                this.map.addControl(scale);
            });
        },
        mqttMsg(){
            this.client  = mqtt.connect('ws://ahdcloud.com:20502/mqtt',MQTT_OPTIONS);
            this.client.on('connect', (e) => {
                console.log(e, "MQTT连接成功！！！");
                const topicList = [
                    `/sudp/+/bucket/${this.projectCode}/+/alarm/get`, // 告警频道
                    `/sudp/+/bucket/${this.projectCode}/+/position/get`, // 设备定位频道
                    `/sudp/+/bucket/${this.projectCode}/+/polygon/get`, // 设备区域频道
                ]
                this.client.subscribe(topicList, {}, (error) => {  // qos 为通道
                    if (!error) {
                        console.log('订阅成功')
                    } else {
                        console.log('订阅失败')
                    }
                })
            })
            // 接收消息处理
            this.client.on('message', (topic, message) => {
                const msg = JSON.parse(message.toString());
                console.log(topic,msg)
                const currentTopic = topic.split('/').slice(-2,-1)[0];
                if( currentTopic== 'alarm'){
                    this.handleAlarmMsg(msg);
                }else if(currentTopic == 'position'){
                    this.handlePositionInfo(msg)
                }else if(currentTopic == 'polygon'){
                    this.showPolygon(msg)
                }
            })
            // 断开发起重连
            this.client.on('reconnect', (error) => {
                console.log('正在重连:', error)
            })
            // 链接异常处理
            this.client.on('error', (error) => {
                console.log('连接失败:', error)
            })
        },
        // 显示marker
        showMarkers(){
            this.map.remove(this.markArr);
            this.markArr = [];

            for(let i=0;i<this.devList.length;i++){
                if(this.devList[i].lon && this.devList[i].lat){
                    let marker;
                    if(this.devList[i].devType == 'bucket'){
                        marker = new AMap.Marker({
                            position: [this.devList[i].lon,this.devList[i].lat],
                            offset: this.devList[i].devStatus == 'connect' ? new AMap.Pixel(-10, -40) : new AMap.Pixel(-10, -10),
                            icon: new AMap.Icon({
                                size: new AMap.Size(28, 34),
                                image: this.iconShow(this.devList[i]),
                                imageSize: new AMap.Size(28, 34),
                                imageOffset: new AMap.Pixel(0, 0)
                            }),
                            label:{
                                offset: new AMap.Pixel(0, 0),  //设置文本标注偏移量
                                content: this.devList[i].devStatus == 'online'? "<span class='online'>"+this.devList[i].devName+"</span>" 
                                        : this.devList[i].devStatus == 'alarm'? "<span class='alarm'>"+this.devList[i].devName+"</span>"
                                        : "<span class='offline'>"+this.devList[i].devName+"</span>", //设置文本标注内容
                                direction: 'top'  //设置文本标注方位
                            }
                        });
                    }else if(this.devList[i].devType == 'lamp'){
                        marker = new AMap.Marker({
                            position: [this.devList[i].lon,this.devList[i].lat],
                            offset: this.devList[i].devStatus == 'connect' ? new AMap.Pixel(-10, -40) : new AMap.Pixel(-10, -10),
                            icon: new AMap.Icon({
                                size: new AMap.Size(28, 34),
                                image: this.iconShow(this.devList[i]),
                                imageSize: new AMap.Size(28, 34),
                                imageOffset: new AMap.Pixel(0, 0)
                            })
                        });
                    }
                    // 绑定单击事件
                    marker.on('click', () => {
                        this.devType = this.devList[i].devType;  // 区分设备类型
                        this.devShow = true;
                        if(this.devList[i].devType === 'bucket'){ // 锥桶
                            this.$api.BUCKET.deviceNewInfo({
                                devCode:this.devList[i].devCode
                            }).then( d => {
                                if(!d){
                                    this.$toast('设备未查询到信息！');
                                    this.dev = {}
                                }else{
                                    this.dev = {...d};
                                    this.dev.devStatus = this.devList[i].devStatus;
                                    this.dev.devName = this.devList[i].devName;
                                    this.dev.devCode = this.devList[i].devCode;
                                    this.dev.lon = this.devList[i].lon;
                                    this.dev.lat = this.devList[i].lat;
                                    this.dev.led = this.optLed.find( v => v.dictValue === d.ledProgram).dictLabel;
                                }
                            })
                        }else {
                            this.$api.BUCKET.LampNewInfo({
                                projectCode:this.projectCode,
                                lampId:this.devList[i].contactId,
                                devCode:this.devList[i].devCode
                            }).then( d => {
                                this.lamp = {...d};
                                if(d.buckets.length !== 0){
                                    this.lamp.distanceText = d.buckets.map( v =>  (v.devName+'距离'+v.distance+'m'));
                                }else{
                                    this.lamp.distanceText = []
                                }
                            })
                        }
                    });
                    this.markArr.push(marker);
                }
            }
            this.map.add(this.markArr);
        },
        // 绘制区域
        showPolygon(data){
            console.log(data);
            this.polygon ? (this.map.remove(this.polygon),this.polygon = null) : void 0;
            this.polygon = new AMap.Polygon({
                path: data,  
                fillColor: '#00CED172', // 多边形填充颜色
                borderWeight: 2, // 线条宽度，默认为 1
                strokeColor: '#00CED172', // 线条颜色
            })
            this.map.add(this.polygon);
        },
        // 图标条件显示
        iconShow(item){
            let img;
            if(item.devType=== 'bucket'){ // 锥桶
                if(item.devStatus === 'offline'){ 
                    img = require('@/assets/bucket/img/cone-offline.png')
                }else if(item.devStatus === 'alarm'){
                    img = require('@/assets/bucket/img/cone-alarm.png')
                }else{
                    img = require('@/assets/bucket/img/cone-normal.png')
                }
                
            }else if(item.devType === 'lamp'){ // 肩灯
                if(item.devStatus === 'offline'){
                    img = require('@/assets/bucket/img/lamp-offline.png')
                }else if(item.devStatus === 'alarm'){
                    img = require('@/assets/bucket/img/lamp-alarm.png')
                }else{
                    img = require('@/assets/bucket/img/lamp-normal.png')
                }
            }
            return img
        },
        // 处理设备定位信息
        handlePositionInfo(data){
            this.devList = data;
            this.showMarkers();
            // 获取中心点
            if(!this.firstView){
                this.firstView = true;
                const allLamp = this.devList.find( v => v.devType === 'lamp');// 全部肩灯
                const onlineLamp = this.devList.find( v => v.devType === 'lamp' && v.devStatus !== 'offline');// 在线肩灯
                if(onlineLamp){
                    this.map.setCenter([onlineLamp.lon, onlineLamp.lat]); 
                }else{
                    allLamp && allLamp.lon && allLamp.lat ? this.map.setCenter([allLamp.lon, allLamp.lat]) : void 0; 
                }
                this.map.setZoom(16); 
            }
        },
        // 处理mq告警信息
        handleAlarmMsg(data){
            const info = `${data.devName} ${data.content} ${data.createTime}`;
            const flagNum = Math.random().toString(16).substr(2,8); // 给这个告警标记
            this.alarmList.push({
                content:info,
                devCode:data.devCode,
                flagNum:flagNum
            });
            // 10秒后清除告警内容
            const alarmTimer = setTimeout(()=>{
                const index = this.alarmList.findIndex( v => v.flagNum === flagNum); // 找到本项索引
                index != -1 ? this.alarmList.splice(index,1) : void 0;
            },10*1000)
        },
        // 跳转参数设置
        goParam(){
            if (window.localStorage.getItem("visitor") == "1") {
                return this.$toast("暂无权限进入设备操控面板");
            }
            if(this.dev.devStatus === 'offline'){
                return this.$toast('设备离线中，待上线后在进行操作！')
            }
            this.$router.push({
                path: "/bucket/param",
                query: { 
                    devCode: this.dev.devCode,
                    devName: this.dev.devName,
                },
            });
        },
        // 确认启动告警
        confirmAlarm(){
            if(this.alarmTypeVal === '1'){
                // 单一设备告警
                this.$api.BUCKET.remoteOneDevAlarm({
                    status:1, 
                    devCode:this.devType === "lamp" ? this.lamp.devCode : this.dev.devCode
                }).then( () => {
                    this.$toast('操作成功');
                })
            }else{
                // 同组设备告警
                this.$api.BUCKET.remoteAlarm({
                    status:1,
                    devCode:this.dev.devCode
                }).then( ()=> {
                    this.$toast('操作成功');
                })
            }
        },
        // 解除告警
        relieve(devCode,index){
            const data = {
                status:0, // 解除 0   告警 1
                devCode:devCode
            }
            this.$api.BUCKET.remoteOneDevAlarm(data).then( d => {
                this.alarmList.splice(index,1);
                this.$toast('解除成功！')
            })
        },
        // 获取led节目单
        loadProgram(){
            this.$api.BUCKET.programInfo('led_program').then( d => {
                Array.isArray(d) ? this.optLed = d : void 0;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.home{
    position: relative;
    .iconfont {
		font-size: 1.45rem;
		color: #fff;
		margin-right: 8px;
	}
	.alarm-red{
		color: #FB4343;
	}
	.set-blue{
		color: #62CDFF;
	}
    .info-control{
        padding: 10px;
        position: absolute;
        bottom: 0;
        background-color: rgba(38, 40, 59, 0.8);
        width: 100%;
        .top{
            display: flex;
            flex-direction:row;
            justify-content: space-between;
            margin-bottom: 5px;
            .handle-area{
                padding-right: 10px;
                .info{
                    padding-right: 8px;
                }
            }
            .title{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-content: center;
                .iconfont{
                    font-weight: bold;
                    color: #EB8204;
                    font-size: 1.35rem;
                }
                .font-title{
                    font-size: .85rem;
                    font-weight: bold;
                    color: #fff;
                    padding-left: 4px;
                }
            }
        }
        
        .font{
            color: #fff;
            font-size: .8rem;
        }
        .font-line{
            color: #fff;
            font-size: .8rem;
            margin-bottom: 5px;
        }
        .inner{
            display: flex;
            flex-direction:row;
            margin-bottom: 8px;
            .block{
                display: flex;
                flex: 1;
                align-items: center;
                .label-0{
                    font-weight: bold;
                }
                .value-0{
                    padding-left: 10px;
                    display: flex;
                }
            }
        }
    }

    .alarm{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #FB4343;
        .title{
            font-weight: bold;
            font-size: 0.95rem;
            color: #fff;
            padding-top: 10px;
            display: inline-block;
        }
        .info{
            overflow-y:auto;
            max-height:180px;
            padding: 10px;
            .text{
                font-size: .8rem;
                color: #fff;
                text-align: left;
            }
            .block-flex{
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;
                .wid-7{
                    width: 88%;
                    text-align: left;
                    .text{
                        line-height:2 ;
                    }
                }
                .wid-3{
                    width: 12%;
                    .relieve{
                        display: inline-block;
                        line-height: 1.5;
                        padding: 4px 6px;
                        background-color: #007AFF;
                        border:none;
                        border-radius: 5px;
                        .text{
                            font-size: .8rem;
                            color: #fff;
                        }
                    }
                }
            }
            
        }
    }
    .dialog-body{
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }
    .online{color:#2b6f96 !important;font-weight: bold;}
    .alarm{color:#d12222 !important;font-weight: bold;}
    .offline{color: #717171 !important;font-weight: bold;}
}
</style>